<template>
  <main>
    <trac-loading v-if="isLoading" />
    <div class="" v-if="allBatches">
      <trac-back-button>Back</trac-back-button>
      <div class="mt-8" v-if="allBatches">
        <p class="text-xs mb-2">All Batches</p>
        <h3 class="font-bold">Total Batches: {{ allBatches.length }}</h3>
      </div>
      <div class="flex justify-end">
        <span class="text-xs text-gray-700"
          >Uploaded batch proccesses will update once completed or you can manually
          <button
            @click="fetchAllBatches"
            class="bg-none border-none text-primaryBlue text-xs font-extrabold"
          >
            refresh batches
          </button></span
        >
      </div>
      <table class="w-full table-auto mt-4">
        <thead class="bg-blue-100">
          <tr class="">
            <!-- <th
                  class="text-left whitespace-no-wrap p-4 text-xs font-semibold"
                >
                  NO.
                </th> -->
            <th class="text-left whitespace-no-wrap p-4 text-xs font-semibold">
              Batch ID
            </th>
            <th class="text-left whitespace-no-wrap p-4 text-xs font-semibold">
              Total Products
            </th>
            <th class="text-left whitespace-no-wrap p-4 text-xs font-semibold">
              Successful Upload
            </th>
            <th class="text-left whitespace-no-wrap p-4 text-xs font-semibold">
              % Uploaded
            </th>
            <th class="text-left whitespace-no-wrap p-4 text-xs font-semibold">
              Status
            </th>
            <th class="text-left whitespace-no-wrap p-4 text-xs font-semibold">
              Date
            </th>
            <th class="text-left whitespace-no-wrap p-4 text-xs font-semibold">
              Action
            </th>
          </tr>
        </thead>
        <tbody v-if="allBatches.length > 0">
          <tr v-for="(batch, index) in allBatches" :key="index">
            <!-- <td class="text-xs whitespace-no-wrap p-4">
                  {{ index + 1 }}
                </td> -->
            <td class="text-xs whitespace-no-wrap p-4">
              {{ batch.BatchId }}
            </td>
            <td class="text-xs whitespace-no-wrap p-4">
              {{ batch.total_product }}
            </td>
            <td class="text-xs whitespace-no-wrap p-4">
              {{ batch.successful_upload }}
            </td>
            <td class="text-xs whitespace-no-wrap p-4">
              {{
                ((batch.successful_upload / batch.total_product) * 100).toFixed(
                  2
                )
              }}%
            </td>
            <td class="text-xs whitespace-no-wrap p-4">
              {{ batch.status }}
            </td>
            <td class="text-xs whitespace-no-wrap p-4">
              {{ moment(batch.created_at).format("DD-MM-YYYY") }}
            </td>
            <td class="text-xs whitespace-no-wrap p-4">
              <button
                v-if="batch.successful_upload < batch.total_product"
                @click="openBatchPage(batch)"
                class="bg-none border-none text-primaryBlue"
              >
                view
              </button>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="8">
              <div class="flex justify-center items-center h-48 w-full">
                <p class="text-gray-600 text-xs">No Batches available.</p>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </main>
</template>

<script>
import moment from "moment";

export default {
  data() {
    return {
      moment,
      allBatches: null,
      isLoading: false,
      timerCtrl: null,
    };
  },
  created() {
    this.fetchAllBatches();
    this.periodicBatchRefresh();
  },
  destroyed() {
    clearInterval(this.timerCtrl);
  },
  computed: {},
  methods: {
    periodicBatchRefresh() {
      this.timerCtrl = setInterval(() => {
        this.backgorundFetchAllBatches();
      }, 30 * 1000); // Run every half minute
    },
    async fetchAllBatches() {
      this.isLoading = true;
      const res = await this.$store.dispatch("FETCH_ALL_BATCHES");
      if (res.status) {
        this.allBatches = res.data || [];
      }
      this.isLoading = false;
    },
    async backgorundFetchAllBatches() {
      const res = await this.$store.dispatch("FETCH_ALL_BATCHES");
      if (res.status) {
        this.allBatches = res.data || [];
        this.requestSync('products', { periodic: false }).then(() => {
          console.log('Product Sync after bulk upload finished')
        })
      }
    },
    openBatchPage(batch) {
      this.$router.push("/products-upload-failed/" + batch.BatchId);
    },
  },
};
</script>

<style>
</style>